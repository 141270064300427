import React, {useState, useEffect, memo, useRef} from 'react'
import styled from "styled-components";

import "./CashFlow style/CashFlow.css";

//component
import CashFlowPopup from './CashFlowPopup.js';

//icon
import { LuFileSpreadsheet } from "react-icons/lu";
import { FaEye } from "react-icons/fa";
import { IoEyeOff } from "react-icons/io5";
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";
import { PiNumberOneBold } from "react-icons/pi";
import { PiNumberThreeBold } from "react-icons/pi";
import { RxDimensions } from "react-icons/rx";
import { BsGraphUp } from "react-icons/bs";

//axios
import axios from "axios";
import ImportiPopup from './ImportiPopup.js';
import moment from 'moment';
import CashFlowGraph from './CashFlowGraph.js';

const CashFlowTable = memo(({mesiPass, mesiNuoviPass, dati, importi, fatture, fattureAcquisto, fattureAcquistoRiba, ricarica, aggiornamento, tipoRicarica, setTipoRicarica, fattureRiba, nascosti, ricaricaLeggera, visualizzazioneTipo}) => {
    
    const identrate = [0, 1000, 2000, 3000, 4000];
    const iduscite = [0, 1000, 2000, 3000, 4000, 5000];
    let totaleFatturaEntrata = [];
    let totaleFatturaUscita = []; 

    const [mesi, setMesi] = useState(null);

    const annoCorrenteOggetto = new Date().getFullYear(); 
    const meseCorrenteOggetto = new Date().getMonth();
    const [mesiNuovi, setMesiNuovi] = useState(() => {
        
        return [
            { mese: 'Mese non valido', anno: annoCorrenteOggetto },
            { mese: 'Gennaio', anno: annoCorrenteOggetto },
            { mese: 'Febbraio', anno: annoCorrenteOggetto },
            { mese: 'Marzo', anno: annoCorrenteOggetto },
            { mese: 'Aprile', anno: annoCorrenteOggetto },
            { mese: 'Maggio', anno: annoCorrenteOggetto },
            { mese: 'Giugno', anno: annoCorrenteOggetto },
            { mese: 'Luglio', anno: annoCorrenteOggetto },
            { mese: 'Agosto', anno: annoCorrenteOggetto },
            { mese: 'Settembre', anno: annoCorrenteOggetto },
            { mese: 'Ottobre', anno: annoCorrenteOggetto },
            { mese: 'Novembre', anno: annoCorrenteOggetto },
            { mese: 'Dicembre', anno: annoCorrenteOggetto },
            { mese: 'Gennaio', anno: annoCorrenteOggetto + 1 },
            { mese: 'Febbraio', anno: annoCorrenteOggetto + 1 },
            { mese: 'Marzo', anno: annoCorrenteOggetto + 1 },
            { mese: 'Aprile', anno: annoCorrenteOggetto + 1 },
            { mese: 'Maggio', anno: annoCorrenteOggetto + 1 },
            { mese: 'Giugno', anno: annoCorrenteOggetto + 1 },
            { mese: 'Luglio', anno: annoCorrenteOggetto + 1 },
            { mese: 'Agosto', anno: annoCorrenteOggetto + 1 },
            { mese: 'Settembre', anno: annoCorrenteOggetto + 1 },
            { mese: 'Ottobre', anno: annoCorrenteOggetto + 1 },
            { mese: 'Novembre', anno: annoCorrenteOggetto + 1 },
            { mese: 'Dicembre', anno: annoCorrenteOggetto + 1 }
        ];
        
    });
    
    const [previsione, setPrevisione] = useState(12);
    const [previsioneGrafico, setPrevisioneGrafico] = useState('ridotto');
    const [indiceCorrente, setIndiceCorrente] = useState(0);

    moment.locale('it');
    const meseCorrenteTemp = moment().format('MMMM')
    const meseCorrente = meseCorrenteTemp.charAt(0).toUpperCase() + meseCorrenteTemp.slice(1);
    const indiceMese = moment().month();
    const annoSuccessivo = parseInt(moment().format('YYYY'))+1;
    const [effettoEseguito, setEffettoEseguito] = useState(false);

    useEffect(() => {
        if (!effettoEseguito) {
            const meseDaCercare = mesiPass[12 - ((12 - indiceMese) + 1)];
            const indice = mesiNuovi.findIndex(item => item.mese === meseDaCercare && item.anno === annoSuccessivo);

            if (indice !== -1) { // Controlla se l'indice è valido
                const nuovoArray = mesiNuovi.slice(0, indice + 1);
                setMesiNuovi(nuovoArray);
            }

            setEffettoEseguito(true); // Segna che l'effetto è stato eseguito una volta
        }
    
    }, [mesiNuovi, indiceMese, annoSuccessivo]);

 

    //visualizzazione iva
    const [visualizzazione, setVisualizzazione] = useState(null);
    const [primoAvvio, setPrimoAvvio] = useState(false);

    useEffect(() => {
        setVisualizzazione(visualizzazioneTipo);
        setPrimoAvvio(true);
    }, [visualizzazioneTipo])  
   
    
    function handleRicarica(){
    
        ricarica();
    }   


    const [completo, setCompleto] = useState(1);

    function radioSelection(num){
        setCompleto(num);
    }

    //mostra nascosti
    const [elementiNascosti, setElementiNascosti] = useState(false);

    function handleElementiNascosti(){
        setElementiNascosti(!elementiNascosti);
    }


    //importi popup
    const [importiMese, setImportiMese] = useState(null);
    const [importiCategoria, setImportiCategoria] = useState(null);
    const [displayImporti, setDisplayImporti] = useState(null);
    const [tipoImporti, setTipoImporti] = useState(null);

    function handleImportiPopup(mese, categoria, tipo){
        setImportiMese(mese);
        setImportiCategoria(categoria);
        if(tipo === 'succ'){
            setTipoImporti(tipo);
        } else {
            setTipoImporti('attuale');
        }
        
        setDisplayImporti(!displayImporti)
    }

    function displayBanca(item, indice, small){
        const idMedium = [1000, 1999, 2000];
        let passId = false;
        if(completo === 1){
            passId = true;
        } else {  
            if(idMedium.includes(item.id)){
                passId = true;
            } 
        }

        

        if(passId){
            return(
                <React.Fragment>
                    <tr>
                        {!small ? (
                            <td className={`block first-column ${item.id === 2000 ? 'last titolo-medium' : item.id === 1000 || item.id === 1999 ? 'titolo-medium' : ''}`}>
                                {item.id === 1000 
                                    ? 'totale conti bancari' 
                                    : item.id === 1999 
                                    ? 'totale altre disponibilità' 
                                    : item.categoria}
                            </td>
                        ) : (
                            <td className={`block first-column titolo`}>
                                inizio periodo
                            </td> 
                        )}
    
                        {showMore && (
                            <td className={`corto ${item.id === 2000 && small ? 'titolo' : ''} ${item.id === 2000 && !small ? 'last titolo-medium' : item.id === 1000 || item.id === 1999 ? 'titolo-medium' : ''} `}></td>
                        )}
    
                        {showMore ? (
                            <td className={`column ${item.id === 2000 && small ? 'titolo' : ''} ${item.id === 2000 && !small ? 'last titolo-medium' : item.id === 1000 || item.id === 1999 ? 'titolo-medium' : ''} ${small ? 'titolo' : ''}`}></td>
                        ) : (
                            <td className={`corto ${item.id === 2000 && small ? 'titolo' : ''} ${item.id === 2000 && !small ? 'last titolo-medium' : item.id === 1000 || item.id === 1999 ? 'titolo-medium' : ''}`}></td>
                        )}
    
                        {mesi.map((mese, index) => (
                            <React.Fragment key={mese}>
                                {showMore ? (
                                    <>
                                        {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mese) ? (
                                            <td className={`column ${item.id === 2000 ? 'last titolo-medium' : item.id === 1000 || item.id === 1999 ? 'titolo-medium' : ''}`}>
                                                <div className='fatture-cash'>
                                                    {Number(item[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                    })}<EyeIcon />
                                                </div>
                                            </td>
                                        ) : (
                                            <td className={`column ${item.id === 2000 && small ? 'titolo' : ''} ${item.id === 2000 && !small ? 'last titolo-medium' : item.id === 1000 || item.id === 1999 ? 'titolo-medium' : ''}`}>
                                                {Number(item[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                })}
                                            </td>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {index === indiceMese && (
                                            <td className={`column ${item.id === 2000 && small ? 'titolo' : ''} ${item.id === 2000 && !small ? 'last titolo-medium' : item.id === 1000 || item.id === 1999 ? 'titolo-medium' : ''}`}></td>
                                        )}
    
                                        {index > indiceMese - 1 && (
                                            <React.Fragment key={mese}>
                                                {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mese) ? (
                                                    <td className={`column ${item.id === 2000 ? 'last titolo-medium' : item.id === 1000 || item.id === 1999 ? 'titolo-medium' : ''}`}>
                                                        <div className='fatture-cash'>
                                                            {Number(item[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                style: 'currency',
                                                                currency: 'EUR'
                                                            })}<EyeIcon />
                                                        </div>
                                                    </td>
                                                ) : (
                                                    <td className={`column ${item.id === 2000 && small ? 'titolo' : ''} ${item.id === 2000 && !small ? 'last titolo-medium' : item.id === 1000 || item.id === 1999 ? 'titolo-medium' : ''}`}>
                                                        {Number(item[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                            style: 'currency',
                                                            currency: 'EUR'
                                                        })}
                                                    </td>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </>
                                )}
                            </React.Fragment>
                        ))}
    
                        {indiceCorrente >= 12 && (
                            <>
                                {mesi.map((mese, indiceMese) => (
                                    <React.Fragment key={indiceMese}>
                                        {(indiceMese <= indiceCorrente - 12) && (
                                            <>
                                                {dati.successivi.banca.map((itemInner, index) => (
                                                    <>
                                                        {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                            <td className={`column ${item.id === 2000 && small ? 'titolo' : ''}  ${item.id === 2000 && !small ? 'last titolo-medium' : item.id === 1000 || item.id === 1999 ? 'titolo-medium' : ''}`}>
                                                                {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                    style: 'currency',
                                                                    currency: 'EUR'
                                                                })}
                                                            </td>
                                                        )}
                                                    </>
                                                ))}
                                            </>
                                        )}
                                    </React.Fragment>
                                ))}
                            </>
                        )}
                    </tr>
    
                    {item.id === 1000 && completo === 1 && (<tr>
                        <td className='block first-column titolo-small'>Altre disponibilità</td>
                    </tr>)}
                        
                </React.Fragment>
            )
        }
        
    }


async function handleNascondi(id, tipo, destinazione){
    const passData = {
        id: id,
        tipo: tipo, 
        dest: destinazione
    }

    try {
        const response = await axios.post("https://www.app.nauticsm.it/nascondi-cashflow", passData);
      
        ricaricaLeggera(); // Chiamata a handleRicarica
    } catch (error) {
        console.error("Errore nella richiesta:", error);
    }

}

    function displayUscite(item, indice, tipo, small){ //tipo: uscite/entrate


        let idArray;
        let passId = false;

        const idAutorizzatiEntrate = [0,1000,2000,3000,4000];
        const idAutorizzatiUscite = [0,1000,2000,3000,4000, 5000];

        if(tipo === 'entrate'){
            idArray = identrate;
            if(completo === 1){
                passId = true;
            } else {
                if(idAutorizzatiEntrate.includes(item.id)){
                    passId = true;
                }
            }
        }else {
            idArray = iduscite;
            if(completo === 1){
                passId = true;
            } else {
                if(idAutorizzatiUscite.includes(item.id)){
                    passId = true;
                }
            }
        }

        if(passId){
            
            if(!nascosti[tipo].includes(item.id)){
                return(

                    <>
                        {item.id === 3000 && tipo === 'uscite' && (  
                        
                            <tr className='column'>
                                <td className='block first-column titolo-small'>
                                    Previsione IVA
                                </td>
                                {showMore && (<td className=' titolo-small corto'></td>)}
                                {showMore ? (
                                    <td className='column titolo-small'></td>
                                ) : (<td className='corto titolo-small'></td>)}
                                {dati.iva.map((item, index) => (
                                    <>  
                                        {showMore ? (
                                            <>
                                                {(index < indiceCorrente) && (
                                                    <td className='column titolo-small'>{Number(item).toLocaleString('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                    })}</td>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {index === indiceMese && (
                                                    <td className='column titolo-small'></td>
                                                )}
                                                {index > indiceMese-1 && (
                                                    <>
                                                        {(index < indiceCorrente) && (
                                                            <td className='column titolo-small'>{Number(item).toLocaleString('it-IT', {
                                                                style: 'currency',
                                                                currency: 'EUR'
                                                            })}</td>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                ))}

                            
                                
                            </tr>
                            
                        )}
                        <tr>
                            
                            <td className={idArray.includes(item.id) ? item.id === idArray[idArray.length-1] ? (`block first-column ${small ? 'titolo' : 'titolo-medium last'}`) : (`block first-column titolo-small`) : ('block first-column') }>
                                
                            <div style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                                <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                                {small ? (<span>{tipo}</span>) : (<span>{item.categoria}</span>)}
                                {!idArray.includes(item.id) && (
                                    <span className='eye-firstColumn' style={{ marginLeft: '8px', verticalAlign: 'middle' }}>
                                    <EyeIcon onClick={() => handleNascondi(item.id, 'nascondi', tipo)} />
                                    </span>
                                )}
                                </div>
                            </div>
                            </td>

                            {showMore && (<td className={idArray.includes(item.id) ? item.id === idArray[idArray.length-1] ? (`corto ${small ? 'titolo' : 'titolo-medium last'}`) : (' titolo-small corto') : (' corto') }></td>)}
                            {showMore ? (<td className={idArray.includes(item.id) ? item.id === idArray[idArray.length-1] ? (`column ${small ? 'titolo' : 'titolo-medium last'}`) : ('column titolo-small') : ('column') }></td>) : (<td className={idArray.includes(item.id) ? item.id === idArray[idArray.length-1] ? (`corto ${small ? 'titolo' : 'titolo-medium last'}`) : ('corto titolo-small') : ('corto') }></td>)}

                            {mesi.map((mesi, index) => (
                                <>
                                    {showMore ? (
                                        <React.Fragment key={mesi}>
                                        {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mesi) ? (
                                        <td className={idArray.includes(item.id) ? (item.id === idArray[idArray.length-1] ? `column ${small ? 'titolo' : 'titolo-medium last'}` : 'column titolo-small') : 'column'}>
                                            <div className='fatture-cash'>
                                                {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                                })}<EyeIcon onClick={() => handleImportiPopup(mesi, item.categoria)}/>
                                            </div>
                                        </td>
                                        ) : (
                                        <td className={idArray.includes(item.id) ? (item.id === idArray[idArray.length-1] ? `column ${small ? 'titolo' : 'titolo-medium last'}` : 'column titolo-small') : 'column'}>
                                            {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                            })}
                                        </td>
                                        )}
                                    </React.Fragment>
                                    ) : (
                                        <>
                                            {index === indiceMese && (
                                                <td className={idArray.includes(item.id) ? (item.id === idArray[idArray.length-1] ? `column ${small ? 'titolo' : 'titolo-medium last'}` : 'column titolo-small') : 'column'}></td>
                                            )}
                                            {index > indiceMese-1 && (
                                                <React.Fragment key={mesi}>
                                                {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mesi) ? (
                                                <td className={idArray.includes(item.id) ? (item.id === idArray[idArray.length-1] ? `column ${small ? 'titolo' : 'titolo-medium last'}` : 'column titolo-small') : 'column'}>
                                                    <div className='fatture-cash'>
                                                        {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                        })}<EyeIcon onClick={() => handleImportiPopup(mesi, item.categoria)}/>
                                                    </div>
                                                </td>
                                                ) : (
                                                <td className={idArray.includes(item.id) ? (item.id === idArray[idArray.length-1] ? `column ${small ? 'titolo' : 'titolo-medium last'}` : 'column titolo-small') : 'column'}>
                                                    {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                    })}
                                                </td>
                                                )}
                                            </React.Fragment>
                                            )}
                                        </>
                                    )}
                                </>
                            ))}
                            
                            {indiceCorrente >= 12 && (
                                <>
                                    {mesi.map((mese, indiceMese) => (
                                    <React.Fragment key={`mese-${indiceMese}`}>
                                        {indiceMese <= indiceCorrente - 12 && (
                                        <>
                                            {dati.successivi[tipo].map((itemInner, index) => {
                                            const value = itemInner[mese.toLowerCase()];
                                            const isValid = value !== undefined && index === indice;
                                            const isMacroCategory = dati.successivi.macro.some(
                                                (importo) =>
                                                importo.categoria === itemInner.categoria && importo.mese === mese
                                            );

                                            const itemClass =
                                                idArray.includes(item.id)
                                                ? item.id === idArray[idArray.length - 1]
                                                    ? `column ${small ? 'titolo' : 'titolo-medium last'}`
                                                    : 'column titolo-small'
                                                : 'column';

                                            if (!isValid) return null;

                                            // Usando una chiave unica composta da item.id e mese
                                            return (
                                                <td className={itemClass} key={`${itemInner.id}-${mese}`}>
                                                <div className="fatture-cash">
                                                    {Number(value).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR',
                                                    })}
                                                    {isMacroCategory && (
                                                    <EyeIcon onClick={() => handleImportiPopup(mese, itemInner.categoria, 'succ')} />
                                                    )}
                                                </div>
                                                </td>
                                            );
                                            })}
                                        </>
                                        )}
                                    </React.Fragment>
                                    ))}
                                </>
                            )}

                            
                        </tr>
                    </>
                )
            } else if(nascosti[tipo].includes(item.id) && elementiNascosti){
                return(

                    <>
                        {item.id === 3000 && tipo === 'entrate' && (
                        
                            <tr className='column'>
                                <td className='block first-column titolo-small'>
                                    Previsione IVA
                                </td>
                                {showMore && (<td className=' titolo-small corto'></td>)}
                                {showMore ? (<td className='column titolo-small'></td>) : (<td className='corto titolo-small'></td>)}
                                {dati.iva.map((item, index) => (
                                    <>
                                        {showMore ? (
                                            <td className='column titolo-small'>{Number(item).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                            })}</td>
                                        ) : (
                                            <>
                                                {index === indiceMese && (
                                                    <td className='column titolo-small'></td>
                                                )}
                                                {index > indiceMese-1 && (
                                                    <td className='column titolo-small'>{Number(item).toLocaleString('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                    })}</td>
                                                )}
                                            </>
                                        )}
                                    </>
                                ))}
                                
                            </tr>
                            
                        )}
                        <tr>
                        <td className={idArray.includes(item.id) ? item.id === idArray[idArray.length-1] ? (`block first-column nascosto ${small ? 'titolo' : 'titolo-medium last'}`) : ('block first-column titolo-small nascosto') : ('block first-column nascosto') }>
                            <div style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                                <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                                {small ? (<span>{tipo}</span>) : (<span>{item.categoria}</span>)}
                                {!idArray.includes(item.id) && (
                                    <span className='eye-firstColumn' style={{ marginLeft: '8px', verticalAlign: 'middle' }}>
                                    <EyeIcon onClick={() => handleNascondi(item.id, 'rivela', tipo)} />
                                    </span>
                                )}
                                </div>
                            </div>
                            </td>


                            {showMore && (<td className={idArray.includes(item.id) ? item.id === idArray[idArray.length-1] ? (`corto nascosto ${small ? 'titolo' : 'titolo-medium last'}`) : (' titolo-small nascosto corto') : (' nascosto corto') }></td>)}
                            
                            {showMore ? (<td className={idArray.includes(item.id) ? item.id === idArray[idArray.length-1] ? (`column nascosto ${small ? 'titolo' : 'titolo-medium last'}`) : ('column titolo-small nascosto') : ('column nascosto') }></td>) : (<td className={idArray.includes(item.id) ? item.id === idArray[idArray.length-1] ? (`corto nascosto ${small ? 'titolo' : 'titolo-medium last'}`) : ('corto titolo-small nascosto') : ('corto nascosto') }></td>)}

                            {mesi.map((mesi, index) => (
                                <>
                                    {showMore ? (
                                        <React.Fragment key={mesi}>
                                        {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mesi) ? (
                                        <td className={idArray.includes(item.id) ? (item.id === idArray[idArray.length-1] ? `column nascosto ${small ? 'titolo' : 'titolo-medium last'}` : 'column titolo-small nascosto') : 'column nascosto'}>
                                            <div className='fatture-cash'>
                                                {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                                })}<EyeIcon onClick={() => handleImportiPopup(mesi, item.categoria)}/>
                                            </div>
                                        </td>
                                        ) : (
                                        <td className={idArray.includes(item.id) ? (item.id === idArray[idArray.length-1] ? `column nascosto ${small ? 'titolo' : 'titolo-medium last'}` : 'column titolo-small nascosto') : 'column nascosto'}>
                                            {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                            })}
                                        </td>
                                        )}
                                    </React.Fragment>
                                    ) : (
                                        <>
                                            {index === indiceMese && (
                                                <td className={idArray.includes(item.id) ? (item.id === idArray[idArray.length-1] ? `column nascosto ${small ? 'titolo' : 'titolo-medium last'}` : 'column titolo-small nascosto') : 'column nascosto'}></td>
                                            )}
                                            {index > indiceMese-1 && (
                                                <React.Fragment key={mesi}>
                                                {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mesi) ? (
                                                <td className={idArray.includes(item.id) ? (item.id === idArray[idArray.length-1] ? `column nascosto ${small ? 'titolo' : 'titolo-medium last'}` : 'column titolo-small nascosto') : 'column nascosto'}>
                                                    <div className='fatture-cash'>
                                                        {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                        })}<EyeIcon onClick={() => handleImportiPopup(mesi, item.categoria)}/>
                                                    </div>
                                                </td>
                                                ) : (
                                                <td className={idArray.includes(item.id) ? (item.id === idArray[idArray.length-1] ? `column nascosto ${small ? 'titolo' : 'titolo-medium last'}` : 'column titolo-small nascosto') : 'column nascosto'}>
                                                    {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                    })}
                                                </td>
                                                )}
                                            </React.Fragment>
                                            )}
                                        </>
                                    )}
                                </>
                            ))}
                            {indiceCorrente >= 12 && (
                                <>
                                {mesi.map((mese, indiceMese) => (
                                <React.Fragment key={indiceMese}>
                                    {indiceMese <= indiceCorrente-12 && (
                                        <>
                                            {dati.successivi[tipo].map((itemInner, index) => (
                                                <>  
                                                    {dati.successivi.macro.some(importo => importo.categoria === itemInner.categoria && importo.mese === mese) ? (
                                                        <>
                                                            {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                                <td className={idArray.includes(item.id) ? (item.id === idArray[idArray.length-1] ? `column nascosto ${small ? 'titolo' : 'titolo-medium last'}` : 'column titolo-small nascosto') : 'column nascosto'}>
                                                                    <div className='fatture-cash'>
                                                                        {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                        style: 'currency',
                                                                        currency: 'EUR'
                                                                        })}<EyeIcon onClick={() => handleImportiPopup(mese, itemInner.categoria, 'succ')}/>
                                                                    </div>
                                                                </td>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                                <td className={idArray.includes(item.id) ? (item.id === idArray[idArray.length-1] ? `column nascosto ${small ? 'titolo' : 'titolo-medium last'}` : 'column titolo-small nascosto') : 'column nascosto'}>
                                                                    {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                    style: 'currency',
                                                                    currency: 'EUR'
                                                                    })}
                                                                </td>
                                                            )}
                                                        </>
                                                    )}
                                                    
                                                </>
                                            ))}
                                        </>
                                    )}
                                </React.Fragment>
                            
                            ))}
                                </>
                            )}
                        </tr>
                    </>
                )
            }

        }  
    }

  //displayFattureEntrate
  const [currentFattura, setCurrentFattura] = useState(null);
    
function displayFattureEntrate(index, fattureRiba, innerType, totale, fatture) {
    const meseCorrente = mesiNuovi[index].mese;
    const annoCorrente = mesiNuovi[index].anno;

    // Se il mese corrente è "Mese non valido", mostra una cella vuota
    if (meseCorrente === "Mese non valido") {
        let fatturaPresente;
        let fatturaPresenteAggiuntiva;

        if(totale === true){
            fatturaPresente = fattureRiba.find(fattura => fattura.mese === meseCorrente );
            fatturaPresenteAggiuntiva = fatture.find(fattura => fattura.mese === meseCorrente );
        } else {
            fatturaPresente = fattureRiba.find(fattura => fattura.mese === meseCorrente );
        }
        
        if (fatturaPresente) {
            // Se c'è un elemento, mostra i dati della fattura
            if(totale && fatturaPresenteAggiuntiva !== null && fatturaPresenteAggiuntiva !== undefined){
                totaleFatturaEntrata.push(parseFloat(fatturaPresente.importo) + parseFloat(fatturaPresenteAggiuntiva.importo));
              

            }
            return (
                <td key={index} className='column'>
                    <div className={totale ? 'fatture-cash titolo-medium' : 'fatture-cash'}>
                        {fatturaPresenteAggiuntiva !== null && fatturaPresenteAggiuntiva !== undefined ? (
                            <>
                                {(Number(fatturaPresente.importo) + Number(fatturaPresenteAggiuntiva.importo)).toLocaleString('it-IT', {
                                    style: 'currency',
                                    currency: 'EUR'
                                })}
                            </>
                        ) : (
                            <>
                                {(Number(fatturaPresente.importo)).toLocaleString('it-IT', {
                                    style: 'currency',
                                    currency: 'EUR'
                                })}
                            </>
                        )}
                        
                        {!totale && (<Eye onClick={() => handleSeePopup(fatturaPresente, 'entrate', index, innerType)} />)}
                    </div>
                </td>
            );
        } else {
            // Se non c'è un elemento, mostra una cella vuota
            return <td key={index} className={totale ? 'column titolo-medium' : 'column'}>/</td>;
        }
    }

    // Verifica se c'è un elemento per il mese corrente e l'anno corrente
    
    let fatturaPresente = fattureRiba.find(fattura => fattura.mese === meseCorrente && fattura.anno === annoCorrente); 
    let fatturaPresenteAggiuntiva;
    
    if(totale === true){
        fatturaPresenteAggiuntiva = fatture.find(fattura => fattura.mese === meseCorrente && fattura.anno === annoCorrente);
        if(totale && fatturaPresenteAggiuntiva !== null && fatturaPresenteAggiuntiva !== undefined && fatturaPresente !== null && fatturaPresente !== undefined){
            totaleFatturaEntrata.push(parseFloat(fatturaPresente.importo) + parseFloat(fatturaPresenteAggiuntiva.importo));
           

        }
    }   


    if (fatturaPresente) {
        // Se c'è un elemento, mostra i dati della fattura
        return (
            <td key={index} className='column'>
                <div className={totale ? 'fatture-cash titolo-medium' : 'fatture-cash'}>
                    {fatturaPresenteAggiuntiva !== null && fatturaPresenteAggiuntiva !== undefined ? (
                        <>
                            {(Number(fatturaPresente.importo) + Number(fatturaPresenteAggiuntiva.importo)).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                            })}
                        </>
                    ) : (
                        <>
                            {(Number(fatturaPresente.importo)).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                            })}
                        </>
                    )}
                    {!totale && (<Eye onClick={() => handleSeePopup(fatturaPresente, 'entrate', index, innerType)} />)}
                </div>
            </td>
        );
    } else {
        // Se non c'è un elemento, mostra una cella vuota
        return <td key={index} className={totale ? 'column titolo-medium' : 'column'}>/</td>;
    }
    
    
}




function displayFattureUscite(index, fatture, innerType, totale, fattureAggiuntive) {
    const meseCorrente = mesiNuovi[index].mese;
    const annoCorrente = mesiNuovi[index].anno;


    // Se il mese corrente è "Mese non valido", mostra una cella vuota
    if (meseCorrente === "Mese non valido") {
        let fatturaPresente;
        let fatturaPresenteAggiuntiva;

        if(totale === true){
            fatturaPresente = fatture.find(fattura => fattura.mese === meseCorrente);
            fatturaPresenteAggiuntiva = fattureAggiuntive.find(fattura => fattura.mese === meseCorrente);
        } else {
            fatturaPresente = fatture.find(fattura => fattura.mese === meseCorrente);
        }
        
        if (fatturaPresente) {
            // Se c'è un elemento, mostra i dati della fattura
            return (
                <td key={index} className='column'>
                    <div className={totale ? 'fatture-cash titolo-medium' : 'fatture-cash'}>
                        {fatturaPresenteAggiuntiva !== null && fatturaPresenteAggiuntiva !== undefined ? (
                            <>
                                {(Number(fatturaPresente.importo) + Number(fatturaPresenteAggiuntiva.importo)).toLocaleString('it-IT', {
                                    style: 'currency',
                                    currency: 'EUR'
                                })}
                            </>
                        ) : (
                            <>
                                {(Number(fatturaPresente.importo)).toLocaleString('it-IT', {
                                    style: 'currency',
                                    currency: 'EUR'
                                })}
                            </>
                        )}
                        
                        {!totale && (<Eye onClick={() => handleSeePopup(fatturaPresente, 'uscite', index, innerType)} />)}
                    </div>
                </td>
            );
        } else {
            // Se non c'è un elemento, mostra una cella vuota
            return <td key={index} className={totale ? 'column titolo-medium' : 'column'}>/</td>;
        }

    }

    // Verifica se c'è un elemento per il mese corrente
    let fatturaPresente = fatture.find(fattura => fattura.mese === meseCorrente && fattura.anno === annoCorrente);
    let fatturaPresenteAggiuntiva;

    if(totale === true){
        fatturaPresenteAggiuntiva = fattureAggiuntive.find(fattura => fattura.mese === meseCorrente && fattura.anno === annoCorrente);
    }

    if (fatturaPresente) {
        // Se c'è un elemento, mostra i dati della fattura
        return (
            <td key={index} className='column'>
                <div className={totale ? 'fatture-cash titolo-medium' : 'fatture-cash'}>
                    {fatturaPresenteAggiuntiva !== null && fatturaPresenteAggiuntiva !== undefined ? (
                        <>
                            {(Number(fatturaPresente.importo) + Number(fatturaPresenteAggiuntiva.importo)).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                            })}
                        </>
                    ) : (
                        <>
                            {(Number(fatturaPresente.importo)).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                            })}
                        </>
                    )}
                    {!totale && (<Eye onClick={() => handleSeePopup(fatturaPresente, 'uscite', index, innerType)} />)}
                </div>
            </td>
        );
    } else {
        // Se non c'è un elemento, mostra una cella vuota
        return <td key={index} className={totale ? 'column titolo-medium' : 'column'}>/</td>;
    }
  }

  const [seePopup, setSeePopup] = useState(false);
  const [tipoFattura, setTipoFattura] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [tipoInterno, setTipoInterno] = useState(null);

  function handleSeePopup(item, tipo, index, interno){
    

    setCurrentIndex(mesiNuovi[index]);
    setTipoFattura(tipo);
    setTipoRicarica(tipo);
    setCurrentFattura(item);
    setTipoInterno(interno);

    setSeePopup(!seePopup);
  }

  useEffect(() => {

    
    if(currentIndex !== null && currentIndex !== undefined){
        if(tipoFattura === 'entrate'){
            if(tipoInterno === 'riba'){
                const oggettoTrovato = fattureRiba.find(oggetto => oggetto.mese === currentIndex.mese);
                setCurrentFattura(oggettoTrovato);
                
            }else{
                const oggettoTrovato = fatture.find(oggetto => oggetto.mese === currentIndex.mese);
                setCurrentFattura(oggettoTrovato);
                
            }
        } else{
            if(tipoInterno === 'riba'){
                const oggettoTrovato = fattureAcquistoRiba.find(oggetto => oggetto.mese === currentIndex.mese);
                setCurrentFattura(oggettoTrovato);
            }else{
                
                
                const oggettoTrovato = fattureAcquisto.find(oggetto => oggetto.mese === currentIndex.mese);
                setCurrentFattura(oggettoTrovato);
            }
        }
    }

  }, [fatture, fattureAcquisto]);



  
  function handlePrevisione(e){
    const {value} = e.target;
    setPrevisione(parseInt(value));
  }
  function handlePrevisioneGrafico(e){
    const {value} = e.target;
    setPrevisioneGrafico(value);
  }

useEffect(() => {
    
    setMesi(mesiPass);
    console.log("chiamata");
    console.log(mesiPass);
    setIndiceCorrente(() => {
        return mesiPass.indexOf(meseCorrente) + previsione
    });
    

}, [mesiPass, previsione]);






useEffect(() => {
    
    if(mesi !== null && mesiNuovi !== null){
        setMesi(mesi.slice(0, indiceCorrente ));
                
    }
}, [indiceCorrente])



    //visualizzazione iva
    async function changeVisualizzazione(){

       if(!primoAvvio){
            const passData = {
                tipo: visualizzazione
            }

            const response = await axios.post('https://www.app.nauticsm.it/cambia-visualizzazione', passData);
            console.log("Chiamata Interna Leggera");
            ricaricaLeggera();
       }
    }

    function handleChangeView(){
        setPrimoAvvio(false);
        if(visualizzazione === 'mensile'){
        setVisualizzazione('trimestrale')
        }else {
        setVisualizzazione('mensile');
        }
        
    }

    useEffect(() => {
        changeVisualizzazione()
    }, [visualizzazione]);


    const [showMore, setShowMore] = useState(false);
    function showAll(){
        setShowMore(!showMore);
    }

    function displayTotale(item, indice, titolo, successivi, saldo){
        
            return(
                <React.Fragment>
                    <tr>
                        <td className={`block first-column titolo-medium ${saldo && 'last'}`}>
                            {titolo}
                        </td>

                        {showMore && (
                            <td className={`corto titolo-medium ${saldo && 'last'}`}></td>
                        )}

                        {showMore ? (
                            <td className={`column titolo-medium ${saldo && 'last'}`}></td>
                        ) : (
                            <td className={`corto titolo-medium ${saldo && 'last'}`}></td>
                        )}

                        {mesi.map((mese, index) => (
                            <React.Fragment key={mese}>
                                {showMore ? (                                        
                                    <td className={`column titolo-medium ${saldo && 'last'} ${saldo && (Number(item[mese.toLowerCase()]) - Number(dati.datiUscite[dati.datiUscite.length - 1][mese.toLowerCase()]) < 0 ? 'negative' : '')}`}>
                                        {saldo ? (
                                            <>
                                                {(Number(item[mese.toLowerCase()]) - Number(dati.datiUscite[dati.datiUscite.length-1][mese.toLowerCase()])).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                })}
                                            </>
                                        ): (
                                            <>
                                                {Number(item[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                })}
                                            </>
                                        )}
                                    </td> 
                                    
                                ) : (
                                    <>
                                        {index === indiceMese && (
                                            <td className={`column titolo-medium ${saldo && 'last'}`}></td>
                                        )}

                                        {index > indiceMese - 1 && (
                                            <React.Fragment key={mese}>
                                                <td className={`column titolo-medium ${saldo && 'last'} ${saldo && (Number(item[mese.toLowerCase()]) - Number(dati.datiUscite[dati.datiUscite.length - 1][mese.toLowerCase()]) < 0 ? 'negative' : '')}`}>
                                                    {saldo ? (
                                                        <>
                                                            {(Number(item[mese.toLowerCase()]) - Number(dati.datiUscite[dati.datiUscite.length - 1][mese.toLowerCase()])).toLocaleString('it-IT', {
                                                                style: 'currency',
                                                                currency: 'EUR'
                                                            })}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {Number(item[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                style: 'currency',
                                                                currency: 'EUR'
                                                            })}
                                                        </>
                                                    )}
                                                </td>

                                            </React.Fragment>
                                        )}
                                    </>
                                )}
                            </React.Fragment>
                        ))}

                        {indiceCorrente >= 12 && (
                            <>
                                {mesi.map((mese, indiceMese) => (
                                    <React.Fragment key={indiceMese}>
                                        {(indiceMese <= indiceCorrente - 12) && (
                                            <>
                                                {dati.successivi[successivi].map((itemInner, index) => (
                                                    <>
                                                        {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                            <>
                                                                {saldo ? (
                                                                    <td className={`column titolo-medium ${saldo && 'last'} ${saldo && (Number(itemInner[mese.toLowerCase()]) - Number(dati.successivi.uscite[dati.successivi.uscite.length-1][mese.toLowerCase()]) < 0 ? 'negative' : '')}`}>
                                                                        {(Number(itemInner[mese.toLowerCase()]) - Number(dati.successivi.uscite[dati.successivi.uscite.length-1][mese.toLowerCase()])).toLocaleString('it-IT', {
                                                                            style: 'currency',
                                                                            currency: 'EUR'
                                                                        })}
                                                                    </td>
                                                                ) : (
                                                                    <td className={`column titolo-medium ${saldo && 'last'}`}>
                                                                        {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                            style: 'currency',
                                                                            currency: 'EUR'
                                                                        })}
                                                                    </td>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                ))}
                                            </>
                                        )}
                                    </React.Fragment>
                                ))}
                            </>
                        )}
                    </tr>
                </React.Fragment>
            )
        
    }

    function displayFinePeriodo(small) {
        return (
            <>
                {showMore && <td className={`column corto ${small ? 'titolo' : 'titolo-medium'}`}></td>}
    
                <td className={showMore ? `${small ? 'titolo' : 'titolo-medium'} column` : `corto ${small ? 'titolo' : 'titolo-medium'}`}></td>
    
                {mesi.map((mese, index) => {
                    let saldoBanca, saldoEntrate, saldoUscite;
                    if (dati.datiBanca.length > 0 && dati.datiEntrate.length > 0 && dati.datiUscite.length > 0) {
                        saldoBanca = Number(dati.datiBanca[dati.datiBanca.length - 1][mese.toLowerCase()]);
                        saldoEntrate = Number(dati.datiEntrate[dati.datiEntrate.length - 1][mese.toLowerCase()]);
                        saldoUscite = Number(dati.datiUscite[dati.datiUscite.length - 1][mese.toLowerCase()]);
                    } else {
                        console.error("Dati non disponibili per il mese richiesto");
                    }
                
                    const saldoTotale = saldoBanca + saldoEntrate - saldoUscite;
                    
                    let percentualeVariazione = saldoBanca !== 0 
                    ? (((saldoTotale - saldoBanca) / saldoBanca) *100).toFixed(2) 
                    : null;
    
                    return (
                        <React.Fragment key={index}>
                            {showMore ? (
                                <td className={`column ${small ? 'titolo' : 'titolo-medium'}`}>
                                    {saldoTotale.toLocaleString('it-IT', {
                                        style: 'currency',
                                        currency: 'EUR',
                                    })} 
                                    {percentualeVariazione !== null && !small && (<><br/><span style={{ fontSize: '12px', marginLeft: '5px' }}>({percentualeVariazione}%)</span></>)}
                                </td>
                            ) : (
                                <>
                                    {index === indiceMese && <td className={`column ${small ? 'titolo' : 'titolo-medium'}`}></td>}
                                    {index > indiceMese - 1 && (
                                        <td className={`column ${small ? 'titolo' : 'titolo-medium'}`} >
                                            {saldoTotale.toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR',
                                            })}
                                            {percentualeVariazione !== null && !small && (
                                                <>
                                                    <br />
                                                    <span style={{ fontSize: '12px' }}>({percentualeVariazione}%)</span>
                                                </>
                                            )}
                                        </td>
                                    
                                    )}
                                </>
                            )}
                        </React.Fragment>
                    );
                })}
    
                {indiceCorrente >= 12 && (
                    <>
                        {mesi.map((mese, indiceMese) => {
                            if (indiceMese <= indiceCorrente - 13){
                                
                                const saldoBancaSuccessivi = Number(dati.successivi.banca[dati.successivi.banca.length - 1][mese.toLowerCase()]);
                                const saldoEntrateSuccessivi = Number(dati.successivi.entrate[dati.successivi.entrate.length - 1][mese.toLowerCase()]);
                                const saldoUsciteSuccessivi = Number(dati.successivi.uscite[dati.successivi.uscite.length - 1][mese.toLowerCase()]);
                                const saldoTotaleSuccessivi = saldoBancaSuccessivi + saldoEntrateSuccessivi - saldoUsciteSuccessivi;
                                
                                let percentualeSuccessivi = saldoBancaSuccessivi !== 0 
                                ? (((saldoTotaleSuccessivi - saldoBancaSuccessivi) / saldoBancaSuccessivi)*100).toFixed(2) 
                                : null;
        
                                return (
                                    <td key={indiceMese} className={`column ${small ? 'titolo' : 'titolo-medium'}`}>
                                        {saldoTotaleSuccessivi.toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR',
                                        })}
                                        {percentualeSuccessivi !== null && !small && (<><br/><span style={{ fontSize: '12px', marginLeft: '5px' }}>({percentualeSuccessivi}%)</span></>)}
                                    </td>
                                );
                            };
                                
                        })}
                    </>
                )}
            </>
        );
    }
    
    const [espandiMenu, setEspandiMenu] = useState(false);
    function viewAll(){
        if(!graphView){
            setEspandiMenu(!espandiMenu);
        }
        
    }

    const [graphView, setGraphView] = useState(false);
    function viewGraph(origine){
        if(!graphView && origine === 'view'){
            setGraphView(false);
        } else {
            setGraphView(!graphView);
        }
        
    }

  return (
    <div>
        <IconMenu>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div className='icon-container tooltip' onClick={() => {viewAll(); viewGraph('view')}}>
                    <span class="tooltiptext">Visualizzazione</span> {/* radioSelection*/}
                    <DimensionIcon onClick={viewAll}/>
                    
                </div>
                {espandiMenu && (
                    <>
                       <div className='icon-container-small' onClick={() => { radioSelection(1); viewAll(); }}>Completo</div>
                       <div className='icon-container-small' onClick={() => { radioSelection(2); viewAll(); }}>Intermedio</div>
                       <div className='icon-container-small' onClick={() => { radioSelection(3); viewAll(); }}>Piccolo</div> 
                    </>
                )}
                <div className='icon-container tooltip' style={{margin: '0px 10px'}} onClick={viewGraph}>
                    <span className='tooltiptext'>Grafico</span>
                    <GraphIcon />
                </div>
                <div className='icon-container tooltip' onClick={handleElementiNascosti}>
                    <span class="tooltiptext">Elementi Nascosti</span>
                    {!elementiNascosti ? (
                        <EyeIcon2 onClick={handleElementiNascosti} />
                    ) : (
                        <EyeIconClose2 onClick={handleElementiNascosti}/>
                    )}
                </div>
                <div className='icon-container tooltip' style={{margin: '0px 10px'}} onClick={handleChangeView}>
                    <span class="tooltiptext">Visualizzazione IVA</span>
                    {visualizzazione === 'mensile' ? (
                        <OneIcon onClick={handleChangeView}/>
                    ) : (
                        <ThreeIcon onClick={handleChangeView}/>
                    )}
                </div>
            </div>        

        </IconMenu>
        <UnderMenu>
            <div style={{display: 'flex', alignItems: 'center', fontSize: '18px'}}>
                {!graphView ? (
                    <>
                        <p>Previsione: </p>
                        <select style={{cursor: 'pointer', marginLeft: '10px'}} onChange={handlePrevisione}>
                            <option value={3}>3 mesi</option>
                            <option value={6}>6 mesi</option>
                            <option selected value={12}>Completo</option>
                        </select>
                    </>
                ) : (
                    <>
                        <p>Grafico: </p>
                        <select
                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                            value={previsioneGrafico} // Imposta il valore dell'elemento <select>
                            onChange={handlePrevisioneGrafico} // Gestore per l'evento di cambio
                        >
                            <option value="ridotto">Ridotto</option>
                            <option value="completo">Completo</option>
                        </select>
                    </>
                )}
            </div>
        </UnderMenu>
        
        {!graphView ? (
            <TableContainer style={{display: 'flex', justifyContent: 'center'}}>
        
            {(dati && fatture && mesi) ? (
                
                <div className="container-all" >
                    <div className='scroll1' >
                        <table className='scroll-table'>
                            <thead >
                                <tr className='intestazione'>
                                    <th className='block first-column'>Categorie</th>
                                    <th className='columnNew corto'>{!showMore ? (
                                        <PlusIcon onClick={showAll} />
                                        ) : (
                                        <MinusIcon onClick={showAll} />
                                        )}
                                    </th>
    
                                    <th className='columnNew'>Precedenti</th>
                                    {mesiNuovi.map((month, index) => (
                                        <React.Fragment key={index}>
                                            {showMore ? (
                                                <>
                                                    {month.mese !== 'Mese non valido' && (
                                                        <>
                                                            {index <= previsione + indiceMese   && (
                                                                <th key={index} className="columnNew">
                                                                    {month.mese}<span style={{fontSize: '12px'}}>({month.anno})</span>
                                                                </th>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            ): (
                                                <>
                                                    {(index >= indiceMese+1 && index <= previsione + indiceMese ) && (
                                                        <th key={index} className="columnNew">
                                                            {month.mese}<span style={{fontSize: '12px'}}>({month.anno})</span>
                                                        </th>
                                                    )}
                                                </>
                                            )}
                                        
                                        </React.Fragment>
                                    ))}
    
                                    
                                </tr>
                            </thead>
                            <tbody>
                                
                                                    
                            {/* <tr>
                                <td className='block first-column totali'></td>
                                <td className='interlinea important' colSpan="30">DEPOSITI BANCARI</td>
                            </tr> */}
                            
                                
                            {dati.datiBanca.map((item, index) => (
                                <>
                                    {index === dati.datiBanca.length-1 && (
                                        displayBanca(item, index, true)
                                    )}
                                </>
                            ))}
                                                        
                            {completo === 1 && ( <tr>
                                <td className='block first-column titolo-small'>Conti Bancari</td>
                            </tr>)}
                            {completo !== 3 && (
                                <>
                                    {dati.datiBanca.map((item, index) => (
                                        displayBanca(item, index, false)
                                    ))}
                                </>
                            )}
                            
                            
    
                            <tr>
                                <td className={completo === 3 ? 'spazio-ridotto' : 'spazio-aggiuntivo'}></td>
                            </tr>
                            {/* <tr>
                                <td className='block first-column totali'></td>
                                <td className='interlinea important' colSpan="30">entrate di gestione</td>
                            </tr> */}
                            {dati.datiEntrate.map((item, index) => (
                                <>
                                    {index === dati.datiEntrate.length-1 && (
                                        displayUscite(item, index, 'entrate', true)
                                    )}
                                </>
                            ))}
                                
                            {completo === 1 && (
                                <>
                                    <tr>
                                        <td className='block first-column titolo-small'>Fatture in entrata</td>
                                    </tr>
                                    <tr className='column'>
                                        <td className='block first-column'>Fatture Entrate Riba</td>
                                        <td className='corto'></td>
                                    
                                        {mesiNuovi.map((mese, index) => (
                                            <>
                                                {index <= indiceCorrente && (
                                                    <>
                                                        {showMore ? (
                                                            <React.Fragment key={index}>
                                                                {displayFattureEntrate(index, fattureRiba, 'riba', false)}
                                                            </React.Fragment>
                                                        ) : (
                                                            <>
                                                                {index === indiceMese && (
                                                                    <>{displayFattureEntrate(0, fattureRiba, 'riba', false)}</>
                                                                )}
                                                                {index > indiceMese && (
                                                                    <React.Fragment key={index}>
                                                                        {displayFattureEntrate(index, fattureRiba, 'riba', false)}
                                                                    </React.Fragment>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        ))}
                                        
                                    </tr>
                                    <tr className='column'>
                                        <td className='block first-column'>Altre Fatture Entrate</td>
                                        <td className=' corto'></td>
                                        
                                        {mesiNuovi.map((mese, index) => (
                                            <>
                                                {index <= indiceCorrente && (
                                                    <>
                                                        {showMore ? (
                                                            <React.Fragment key={index}>
                                                                {displayFattureEntrate(index, fatture, 'normali', false)}
                                                            </React.Fragment>
                                                        ): (
                                                            <>
                                                                {index === indiceMese && (
                                                                    <>{displayFattureEntrate(0, fatture, 'normali', false)}</>
                                                                )}
                                                                {index > indiceMese && (
                                                                    <React.Fragment key={index}>
                                                                    {displayFattureEntrate(index, fatture, 'normali', false)}
                                                                </React.Fragment>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        ))}
                                    </tr>
                                </>
                            )}
                            {completo !== 3 && (
                                <tr>
                                    <td className='block first-column titolo-medium'>totale fattura in entrata</td>
                                    <td className=' corto'></td>
                                    {mesiNuovi.map((mese, index) => (
                                        <>
                                            {index <= indiceCorrente && (
                                                <>
                                                    {showMore ? (
                                                        <React.Fragment key={index}>
                                                            {displayFattureEntrate(index, fattureRiba, 'riba', true, fatture)}
                                                        </React.Fragment>
                                                    ) : (
                                                        <>
                                                            {index === indiceMese && (
                                                                <>{displayFattureEntrate(0, fattureRiba, 'riba', true, fatture)}</>
                                                            )}
                                                            {index > indiceMese && (
                                                                <React.Fragment key={index}>
                                                                    {displayFattureEntrate(index, fattureRiba, 'riba', true, fatture)}
                                                                </React.Fragment>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ))}
                                </tr>
                            )}
                            <tr>
                                <td className='spazio-ridotto'></td>
                            </tr>
                                                
                            {nascosti && completo !==3 && (
                                <>
                                    {dati.datiEntrate.map((item, index) => (
                                        displayUscite(item, index, 'entrate')
                                    ))}
                                </>
                            )}
                            
                            <tr>
                                <td className={completo === 3 ? 'spazio-ridotto' : 'spazio-aggiuntivo'}></td>
                            </tr>                       
    
                            {dati.datiUscite.map((item, index) => (
                                <>
                                    {index === dati.datiUscite.length-1 && (
                                        displayUscite(item, index, 'uscite', true)
                                    )}
                                </>
                            ))}
                            
                            {completo === 1 && (
                                <>
                                    <tr>
                                        <td className='block first-column titolo-small'>Fatture in uscita</td>
                                    </tr>
                                    <tr className='column'>
                                        <td className='block first-column'>Fatture Uscite Riba</td>
                                        <td className=' corto'></td>
                                        
                                        {mesiNuovi.map((mese, index) => (
                                            <>
                                                {index <= indiceCorrente && (
                                                    <>
                                                        {showMore ? (
                                                            <React.Fragment key={index}>
                                                                {displayFattureUscite(index, fattureAcquistoRiba, 'riba', false)}
                                                            </React.Fragment>
                                                        ): (
                                                            <>
                                                                {index === indiceMese && (
                                                                    <>{displayFattureUscite(0, fattureAcquistoRiba, 'riba', false)}</>
                                                                )}
                                                                {index > indiceMese && (
                                                                    <React.Fragment key={index}>
                                                                    {displayFattureUscite(index, fattureAcquistoRiba, 'riba', false)}
                                                                </React.Fragment>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        ))}
                                    </tr>
                                    <tr className='column'>
                                        <td className='block first-column'>Altre Fatture Uscite</td>
                                        <td className=' corto'></td>
                                        
                                        {mesiNuovi.map((mese, index) => (
                                            <>
                                                {index <= indiceCorrente && (
                                                    <>
                                                        {showMore ? (
                                                            <React.Fragment key={index}>
                                                            {displayFattureUscite(index, fattureAcquisto, 'normali', false)}
                                                        </React.Fragment>
                                                        ) : (
                                                            <>
                                                                {index === indiceMese && (
                                                                    <>{displayFattureUscite(0, fattureAcquisto, 'normali', false)}</>
                                                                )}
                                                                {index > indiceMese && ( 
                                                                    <React.Fragment key={index}>
                                                                    {displayFattureUscite(index, fattureAcquisto, 'normali', false)}
                                                                </React.Fragment>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        ))}
                                    </tr>
                                </>
                            )}
    
    
                            {completo !== 3 && (
                                <tr>
                                    <td className='block first-column titolo-medium'>totale fattura in uscita</td>
                                    <td className=' corto'></td>
                                    {mesiNuovi.map((mese, index) => (
                                        <>
                                            {index <= indiceCorrente && (
                                                <>
                                                    {showMore ? (
                                                        <React.Fragment key={index}>
                                                            {displayFattureUscite(index, fattureAcquistoRiba, 'riba', true, fattureAcquisto)}
                                                        </React.Fragment>
                                                    ): (
                                                        <>
                                                            {index === indiceMese && (
                                                                <>{displayFattureUscite(0, fattureAcquistoRiba, 'riba', true, fattureAcquisto)}</>
                                                            )}
                                                            {index > indiceMese && (
                                                                <React.Fragment key={index}>
                                                                {displayFattureUscite(index, fattureAcquistoRiba, 'riba', true, fattureAcquisto)}
                                                            </React.Fragment>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ))}
                                </tr>
                            )}
    
                            <tr>
                                <td className='spazio-ridotto'></td>
                            </tr>                       
    
                            {nascosti && completo !== 3 && (
                                <>
                                    {dati.datiUscite.map((item, index) => (
                                        displayUscite(item, index, 'uscite')
                                    ))}
                                </>
                            )}
                            
                            <tr>
                                <td style={{paddingLeft: '0px', paddingRight: '0px'}} className={completo === 3 ? 'spazio-ridotto ' : 'spazio-aggiuntivo'}><hr/></td>
                                <td style={{paddingLeft: '0px', paddingRight: '0px'}} colSpan={30}><hr/></td>
                            </tr> 
                            
    
                            <tr>
                                <td className='block first-column titolo'>fine periodo</td>
                                {displayFinePeriodo(true)}
                            </tr>
                            {dati.datiBanca.map((item, index) => (
                                <>
                                    {index === dati.datiBanca.length-1 && (
                                        displayTotale(item, index, 'inizio periodo', 'banca')
                                    )}
                                </>
                                
                            ))}                        
                            {dati.datiEntrate.map((item, index) => (
                                <>
                                    {index === dati.datiEntrate.length-1 && (
                                        displayTotale(item, index, 'entrate', 'entrate')
                                    )}
                                </>
                            ))}
                            {dati.datiUscite.map((item, index) => (
                                <>
                                    {index === dati.datiUscite.length-1 && (
                                        displayTotale(item, index, 'uscite', 'uscite')
                                    )}
                                </>
                            ))}
                            {dati.datiEntrate.map((item, index) => (
                                <>
                                    {index === dati.datiEntrate.length-1 && (
                                        displayTotale(item, index, 'Saldo entrate/uscite', 'entrate', true)
                                    )}
                                </>
                            ))}
    
    
                            <tr>
                                <td className='spazio-ridotto'></td>
                            </tr>
                            <tr>
                                <td className='block first-column titolo-medium'>fine periodo</td>
                                {displayFinePeriodo()}
                            </tr>
    
    
                            </tbody>
    
                        </table>
    
                    </div>                
                    
                </div>
        
            ) : (
                <p>Caricamento...</p>
            )}
            
            </TableContainer>
        ) : (
            <CashFlowGraph previsioneGrafico={previsioneGrafico}/>
        )}

        {seePopup && (<CashFlowPopup close={handleSeePopup} fattura={currentFattura} tipo={tipoFattura} ricarica={handleRicarica} aggiornamento={aggiornamento}/>)}
        {displayImporti && (<ImportiPopup mese={importiMese} categoria={importiCategoria} close={handleImportiPopup} tipo={tipoImporti}/>)}
    </div>

    
  );
});

export default CashFlowTable;

const IconMenu = styled.div`
    width: 100%;
    margin-top: 30px;
    padding-left: 2%;
    padding-right: 2%;

    .icon-container{
        border: 1px solid rgba(0,0,0,.5);
        border-bottom: ${props => (props.seeFilter ? 'none' : '1px solid rgba(0,0,0,.5)')};
        width: fit-content;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        border-radius: ${props => (props.seeFilter ? '5px 5px 0px 0px' : '5px')};
        cursor: pointer;
        color: rgba(0,0,0,.5);
        transition: all .3s linear;
    }

    .icon-container:hover, .icon-container-small:hover{
        border: 1px solid black;
        color: black;
    }

    .icon-container-small{
        border: 1px solid rgba(0,0,0,.5);
        border-bottom: ${props => (props.seeFilter ? 'none' : '1px solid rgba(0,0,0,.5)')};
        width: fit-content;
        padding: 5px 10px;
        display: flex;
        font-size: 12px;
        margin-left: 10px;
        align-items: center;
        border-radius: ${props => (props.seeFilter ? '5px 5px 0px 0px' : '5px')};
        cursor: pointer;
        color: rgba(0,0,0,.5);
        transition: all .3s linear;
    }

`
const DimensionIcon = styled(RxDimensions)`
  font-size: 20px;
  cursor: pointer;  
`
const GraphIcon = styled(BsGraphUp)`
  font-size: 20px;
  cursor: pointer;  
`

const UnderMenu = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding-left: 2%;
    padding-right: 2%;
`

const UpperMenu = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .radio{
    
    margin-top: 20px;
    display: flex;
    align-items: center;
  }

  .radio input, .radio label{
    cursor: pointer;
    font-size: 20px;
  }

  .radio label{
    margin-right: 15px;
  }

  .visualizzazione-iva{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  .visualizzazione-switch{
    width: 80px;
    background-color: rgba(0,0,0,.2);
    border-radius: 20px;
    height: 25px;
    margin: 0px 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    ${({ visualizzazione }) => (visualizzazione === 'mensile' ? 'justify-content: flex-start;' : 'justify-content: flex-end;')};
  }

  .inner-switch{
     width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #213940;
        cursor: pointer;
        margin: 0px 3px;
  }


`

const TableContainer = styled.div`
    .negative{
        color: red;
    }

    .eye-firstColumn{
        color: rgba(0,0,0,.3);
        transition: all .3s linear;
    }
    .first-column:hover{
        .eye-firstColumn{
            color: rgba(0,0,0,1);
            cursor: pointer;
        }    
    }

    .nascosto{
        color: rgba(0,0,0,.3);
    }

    .container-all{
        display: flex;
        margin-top: 30px;        
        overflow-x: auto;
        width: 96%;
        
    }

    .scroll1{
        overflow-x: auto;
        white-space: nowrap;
        border-radius: 10px;
        width: 100%;
        
    }

    table{
        width: 100%;
    }

    .scroll1 td.first-column {
    position: sticky;
    left: 0;
    z-index: 3;


    }

    .scroll1 td.first-column::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0; /* Estendi il bordo per tutta l'altezza della cella */
        left: -1px; /* Posiziona il bordo sinistro */
        width: 2px; /* Larghezza del bordo sinistro */
        background-color: var(--blue-navy); /* Colore del bordo */
        z-index: 1; /* Imposta uno z-index inferiore rispetto alla cella */
    }

    .scroll1 td.first-column::after {
        content: '';
        position: absolute;
        top: 0;
        right: -2px; 
        height: 100%;
        width: 2px; 
        background-color: var(--blue-navy);
        z-index: 2;
    }

    .scroll1 td.spazio-ridotto {
        position: sticky;
        left: 0;
        z-index: 2;
        
    }

    .scroll1 td.spazio-ridotto::before {
        content: '';
        position: absolute;
        top: 0;
        left: -1px; /* Posiziona il bordo sinistro */
        height: 100%;
        width: 2px; /* Larghezza del bordo sinistro */
        background-color: var(--blue-navy); /* Colore del bordo */
        z-index: 1; /* Imposta uno z-index inferiore rispetto alla cella */
    }

    .scroll1 td.spazio-ridotto::after {
        content: '';
        position: absolute;
        top: 0;
        right: -2px; /* Posiziona il bordo destro */
        height: 100%;
        width: 2px; /* Larghezza del bordo destro */
        background-color: var(--blue-navy); /* Colore del bordo */
        z-index: 1; /* Imposta uno z-index inferiore rispetto alla cella */
    }


    .scroll1 td.spazio-aggiuntivo {
        position: sticky;
        left: 0;
        z-index: 2;
        padding: 15px 0px;
    }

    .scroll1 td.spazio-aggiuntivo::before {
        content: '';
        position: absolute;
        top: 0;
        left: -1px; /* Posiziona il bordo sinistro */
        height: 100%;
        width: 2px; /* Larghezza del bordo sinistro */
        background-color: var(--blue-navy); /* Colore del bordo */
        z-index: 1; /* Imposta uno z-index inferiore rispetto alla cella */
    }

    .scroll1 td.spazio-aggiuntivo::after {
        content: '';
        position: absolute;
        top: 0;
        right: -2px; /* Posiziona il bordo destro */
        height: 100%;
        width: 2px; /* Larghezza del bordo destro */
        background-color: var(--blue-navy); /* Colore del bordo */
        z-index: 1; /* Imposta uno z-index inferiore rispetto alla cella */
    }



    th.corto, td.corto{
        min-width: 22px;
        position: relative;
    }

    .titolo{
        font-weight: bold;
        text-decoration: underline;
        color: #1eafd6;
        font-size: 18px;
        text-transform: uppercase;
    }

    .titolo-small{
        font-weight: bold;
                
    }

    .titolo-medium{
        font-weight: bold;
        text-transform: uppercase;
    }


`

const Choice = styled.div`
    display: flex;
    align-items: center;
    padding-left: 2%;
    padding-right: 2%;

    .switch-view{
        width: 80px;
        background-color: rgba(0,0,0,.2);
        border-radius: 20px;
        height: 25px;
        margin: 0px 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: ${({completo}) => ((completo === 1) ? 'flex-start' : 'flex-end')};
    }

    .switch-view-nasc{
        width: 80px;
        background-color: rgba(0,0,0,.2);
        border-radius: 20px;
        height: 25px;
        margin: 0px 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: ${({elementiNascosti}) => (elementiNascosti ? 'flex-start' : 'flex-end')};
    }

    .switch-btn{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #213940;
        cursor: pointer;
        margin: 0px 3px;
    }

    
`

const Eye = styled(LuFileSpreadsheet)`
    margin-left: 7px;
    text-decoration: underline;
    cursor: pointer;
    font-size: 20px;
    &:hover{
        
        color: rgba(0,0,0,.7)
    }
`

const EyeIcon = styled(FaEye)`
    font-size: 20px;
    margin-left: 7px;
    cursor: pointer;
`


const EyeIconClose = styled(IoEyeOff)`
    font-size: 20px;
    margin-left: 7px;
    cursor: pointer;
`

const EyeIcon2 = styled(FaEye)`
    font-size: 20px;
    cursor: pointer;
`


const EyeIconClose2 = styled(IoEyeOff)`
    font-size: 20px;
    cursor: pointer;
`

const PlusIcon = styled(CiCirclePlus)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
    font-weight: 800;
    cursor: pointer;
    
`

const MinusIcon = styled(CiCircleMinus)`
   position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
    font-weight: 800;
    cursor: pointer;
    
`

const OneIcon = styled(PiNumberOneBold)`
    font-size: 20px;
    cursor: pointer;
`

const ThreeIcon = styled(PiNumberThreeBold)`
    font-size: 20px;
    cursor: pointer;
`