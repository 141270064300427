import React, {useState, useEffect} from 'react'

import styled from "styled-components"
import moment from "moment";
import 'moment/locale/it'; 


//grafico
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

//icon
import { IoTriangle } from "react-icons/io5";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

function Fatturato({dati, datiGrafico, datiAggiuntivi}) {
    const formatEuro = (importo) => `${importo.toFixed(2)} €`;
    //anno e mese corrente
    moment.locale('it');
    const meseCorrente = moment().format('MMMM');
    const dataMeseScorso = moment().subtract(1, 'months');
    const meseScorso = dataMeseScorso.format('MMMM');
    const dataMeseScorsoAv = moment().subtract(2, 'months').format('MMMM');

    const giornoCorrente = moment().format('DD');

    const annoCorrente = moment().format('YYYY');
    const dataCorrente = moment();
    const annoAttuale = dataCorrente.year();
    
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="recharts-tooltip">
              <p className="label title-label">{`${label}`}</p>
              <p className="label first-label">{`${annoAttuale} : ${formatEuro(payload[0].value)}`}</p>
              <p className="label second-label">{`${annoAttuale-1} : ${formatEuro(payload[1].value)}`}</p>
            </div>
          );
        }
      
        return null;
    };

    return (
        <Container>
            {dati && (<InnerContainer>
                <div className='title-info'>
                        <h2>Fatturato</h2>
                        <p className='mese-corrente'>{meseCorrente.charAt(0).toUpperCase() + meseCorrente.slice(1)}: {Number(dati.fatturatoMeseCorrente).toLocaleString('it-IT', {
                                  style: 'currency',
                                  currency: 'EUR'
                        })}</p>
                </div>
                <FlexContainer>
                    <div className='left-info'>
                        

                        <div className='mese-scorso'>
                            <div className='mese-scorso-title'>
                                <h4>{meseScorso.charAt(0).toUpperCase() + meseScorso.slice(1)}</h4>
                            </div>
                            <div className='mese-scorso-info'>
                                <div className='mese-scorso-dati'>
                                    <div className='mese-scorso-rows'>
                                        {meseScorso === 'dicembre' ? (<p>{annoCorrente-1}</p>) : (<p>{annoCorrente}</p>)}
                                        <p className='fatturato'>{Number(dati.fatturatoMeseScorso).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                        })}</p>
                                    </div>
                                    <div className='mese-scorso-rows'>
                                        {meseScorso === 'dicembre' ? (<p>{annoCorrente-2}</p>) : (<p>{annoCorrente-1}</p>)}
                                        <p className='fatturato-scorso'>{Number(dati.fatturatoMeseScorsoAnno).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                        })}</p>
                                    </div>
                                </div>
                                <div className='mese-scorso-andamento'>
                                    <p>{((dati.fatturatoMeseScorso - dati.fatturatoMeseScorsoAnno) / dati.fatturatoMeseScorsoAnno * 100).toFixed(2)}%</p>
                                    {((dati.fatturatoMeseScorso - dati.fatturatoMeseScorsoAnno) / dati.fatturatoMeseScorsoAnno * 100 >= 0) ? (<UpTriangle />) : (<DownTriangle />)}
                                </div>
                            </div>
                        </div>
                        <div className='anno-scorso'>
                            <div className='anno-scorso-title'>
                                <h4>Anno fino ad oggi</h4>
                            </div>
                            <div className='mese-scorso-info'>
                                <div className='mese-scorso-dati'>
                                    <div className='mese-scorso-rows'>
                                        <p>{annoCorrente}</p>
                                        <p className='fatturato'>{Number(dati.fatturatoAttuale).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                        })}</p>
                                    </div>
                                    <div className='mese-scorso-rows'>
                                        <p>{annoCorrente-1}</p>
                                        <p className='fatturato-scorso'>{Number(dati.fatturatoScorso).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                        })}</p>
                                    </div>
                                </div>
                                <div className='mese-scorso-andamento'>
                                    <p>{((dati.fatturatoAttuale - dati.fatturatoScorso) / dati.fatturatoScorso * 100).toFixed(2)}%</p>
                                    {((dati.fatturatoAttuale - dati.fatturatoScorso) / dati.fatturatoScorso * 100 >= 0) ? (<UpTriangle />) : (<DownTriangle />)}
                                </div>
                            </div>
                        </div>
                        
                    
                    </div>
                    <div className='right-info'>
                        {datiGrafico && (
                            <ResponsiveContainer width="100%" height={300}>
                                <AreaChart data={datiGrafico}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="mesi" />
                                <YAxis />
                                <Tooltip content={<CustomTooltip />} />
                                <Area type="monotone" dataKey={`es_${annoAttuale}`} stackId="2" stroke="#1eafd6" fill="#1eafd6" />
                                <Area type="monotone" dataKey={`es_${annoAttuale - 1}`} stackId="1" stroke="#D5D5D6" fill="#D5D5D6" />
                                </AreaChart>
                            </ResponsiveContainer>
                        )}
                    </div>
                </FlexContainer>
            </InnerContainer>)}
            {(dati && datiAggiuntivi) && (<InnerContainer>
                <div className='second-title'>
                    <h2>Fatturato Vendite</h2>
                </div>
                
                <div className='second-info'>
                    <ul className='lista-generale'>
                        <li>{meseCorrente.charAt(0).toUpperCase() + meseCorrente.slice(1)} {annoCorrente} (dal 1 al {giornoCorrente})
                            <ul className='rientro'>
                                <li>vs. {meseScorso.charAt(0).toUpperCase() + meseScorso.slice(1)} {meseScorso == 'dicembre' ? (annoCorrente-1) : (annoCorrente)} (dal 1 al {giornoCorrente})</li>
                                <li>vs. {meseCorrente.charAt(0).toUpperCase() + meseCorrente.slice(1)} {annoCorrente-1} (dal 1 al {giornoCorrente})</li>
                            </ul>
                        </li>
                        <li>{meseScorso.charAt(0).toUpperCase() + meseScorso.slice(1)} {meseScorso == 'dicembre' ? (annoCorrente-1) : (annoCorrente)}
                            <ul className='rientro'>
                                <li>vs. {dataMeseScorsoAv.charAt(0).toUpperCase() + dataMeseScorsoAv.slice(1)} {meseScorso == 'dicembre' ? (annoCorrente-1) : (annoCorrente)}</li>
                                <li>vs. {meseScorso.charAt(0).toUpperCase() + meseScorso.slice(1)} {meseScorso == 'dicembre' ? (annoCorrente-2) : (annoCorrente-1)}</li>
                            </ul>
                        </li>
                        <li>Anno {annoCorrente} (dal 1 Gennaio al {giornoCorrente} {meseCorrente.charAt(0).toUpperCase() + meseCorrente.slice(1)})
                            <ul className='rientro'>
                                <li>vs. Anno {annoCorrente-1} (dal 1 Gennaio al {giornoCorrente} {meseCorrente.charAt(0).toUpperCase() + meseCorrente.slice(1)})</li>
                            </ul>
                        </li>
                        <li>Anno {annoCorrente-1}
                            <ul className='rientro'>
                                <li>vs. Anno {annoCorrente-2}</li>
                            </ul>
                        </li>

                    </ul>
                    <ul className='lista-generale'>
                        <li>{Number(dati.fatturatoMeseCorrente).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                            })}
                            <ul className='rientro'>
                                <li>{Number(datiAggiuntivi.fatturatoMeseScorso).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                                })} {((dati.fatturatoMeseCorrente-datiAggiuntivi.fatturatoMeseScorso)/datiAggiuntivi.fatturatoMeseScorso * 100) >= 0 ? (<UpArrow />) : (<DownArrow />)} ({((dati.fatturatoMeseCorrente-datiAggiuntivi.fatturatoMeseScorso)/datiAggiuntivi.fatturatoMeseScorso * 100).toFixed(0)}%)</li>

                                <li>{Number(datiAggiuntivi.fatturatoAnnoScorso).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                                })} {((dati.fatturatoMeseCorrente-datiAggiuntivi.fatturatoAnnoScorso)/datiAggiuntivi.fatturatoAnnoScorso * 100) >= 0 ? (<UpArrow />) : (<DownArrow />)} ({((dati.fatturatoMeseCorrente-datiAggiuntivi.fatturatoAnnoScorso)/datiAggiuntivi.fatturatoAnnoScorso * 100).toFixed(0)}%)</li>
                            </ul>
                        </li>
                        <li>{Number(dati.fatturatoMeseScorso).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                                })}
                            <ul className='rientro'>
                                <li>{Number(datiAggiuntivi.meseScorsoAv).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                                })} {((dati.fatturatoMeseScorso-datiAggiuntivi.meseScorsoAv)/datiAggiuntivi.meseScorsoAv * 100) >= 0 ? (<UpArrow />) : (<DownArrow />)} ({((dati.fatturatoMeseScorso-datiAggiuntivi.meseScorsoAv)/datiAggiuntivi.meseScorsoAv * 100).toFixed(0)}%)</li>

                                <li>{Number(dati.fatturatoMeseScorsoAnno).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                                })} {((dati.fatturatoMeseScorso-dati.fatturatoMeseScorsoAnno)/dati.fatturatoMeseScorsoAnno * 100) >= 0 ? (<UpArrow />) : (<DownArrow />)} ({((dati.fatturatoMeseScorso-dati.fatturatoMeseScorsoAnno)/dati.fatturatoMeseScorsoAnno * 100).toFixed(0)}%)</li>
                            </ul>
                        </li>
                        <li>{Number(dati.fatturatoAttuale).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                                })}

                            <ul className='rientro'>
                                <li>{Number(dati.fatturatoScorso).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                                })} {((dati.fatturatoAttuale-dati.fatturatoScorso)/dati.fatturatoScorso * 100) >= 0 ? (<UpArrow />) : (<DownArrow />)} ({((dati.fatturatoAttuale-dati.fatturatoScorso)/dati.fatturatoScorso * 100).toFixed(0)}%)</li>
                            </ul>

                        </li>
                        <li>{Number(datiAggiuntivi.annoScorso).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                                })}
                            <ul className='rientro'>
                                <li>{Number(datiAggiuntivi.annoScorsoAv).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                                })} {((datiAggiuntivi.annoScorso-datiAggiuntivi.annoScorsoAv)/datiAggiuntivi.annoScorsoAv * 100) >= 0 ? (<UpArrow />) : (<DownArrow />)} ({((datiAggiuntivi.annoScorso-datiAggiuntivi.annoScorsoAv)/datiAggiuntivi.annoScorsoAv * 100).toFixed(0)}%)</li>
                            </ul>
                        </li>

                    </ul>
                </div>
                
            </InnerContainer>)}
        </Container>
    )
}

export default Fatturato;

const DownArrow = styled(IoIosArrowDown)`
    color: red;
    font-size: 20px;
    margin: 0px 5px;
`

const UpArrow = styled(IoIosArrowUp)`
    color: green;
    font-size: 20px;
    margin: 0px 5px;
`   

const Container = styled.div`
    
    display: flex;
    align-items: center;
    margin-bottom: 100px;
    flex-direction: column;

`
const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px;

    .left-info, .right-info{
        width: 50%;
    }

    .right-info{
        min-width: 400px;
        
    }

    .left-info{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
    }

    .mese-scorso{
        
        font-size: 18px;
        border-radius: 10px;
        width: 80%;
        border: 2px solid #1eafd6;
        max-width: 500px;
        min-width: 300px;
    }

    .mese-corrente{
        font-size: 18px;
    }

    .mese-scorso-rows{
        display: flex;
        justify-content: space-between;
        margin: 8px 0px;
    }

    .mese-scorso-info{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0px;
        margin-left: 10px;
    }

    .mese-scorso-dati{
        width: 70%;
        
    }
    .mese-scorso-andamento{
        width: 30%;
    }
    .mese-scorso-title{
        
        h4{
            font-size: 22px;
            padding: 10px 0px;
            color: white;
        }

        text-align: center;
        background-color: #1eafd6;
    }

    .fatturato{
        font-size: 22px;
        font-weight: 800;
    }

    .mese-scorso-andamento{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .anno-scorso-title{
         
        h4{
            font-size: 22px;
            padding: 10px 0px;
            color: white;
        }

        text-align: center;
        background-color: #213940;
    }

    .anno-scorso{
        min-width: 300px;
        margin-top: 30px;
        font-size: 18px;
        border-radius: 10px;
        width: 80%;
        border: 2px solid #213940;
        max-width: 500px;
    }
`
const InnerContainer = styled.div`
    width: 80%;
    border: 2px solid rgba(0,0,0,.2);
    
    margin-bottom: 100px;
    border-radius: 10px;
    padding: 20px 50px;
    
    .second-title{
        border-bottom: 2px solid rgba(0,0,0,.2);
        h2{
            color: #1eafd6;
            padding-bottom: 7px;
        }
    }

    .rientro{
        padding-left: 15px;
    }

    .rientro > li{
        padding: 7px 0px;
        font-size: 18px;
        font-weight: normal;
        color: rgba(0,0,0,.7);
        display: flex;
        align-items: center;
    }

    .second-info{
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        ul{
            list-style: none;

        }

        .lista-generale > li{
            padding: 10px 0px;
            font-size: 20px;
            font-weight: bold;
        }
    }

  

    
`

const UpTriangle = styled(IoTriangle)`
    color: green;
`

const DownTriangle = styled(IoTriangle)`
    color: red;
    transform: rotate(180deg);
`