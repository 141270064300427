import React from "react";
import styled, { keyframes } from "styled-components";

function Benvenuto({ username }) {
  return (
    <Container>Benvenuto {username}!</Container>
  )
}

export default Benvenuto;

// Animazioni
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

// Styled Component
const Container = styled.div`
  z-index: 9999;
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, .9);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 40px;

  animation: ${fadeIn} 1s ease-in, ${fadeOut} 3s 3.5s ease-out forwards;
`;
